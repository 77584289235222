<template>
  <div id="waveform-container">
    <AVMedia v-if="micInitialized && config.voice_interface.type === 'frequ'" id="waveform" :media="media"
      :type="config.voice_interface.type" :frequ-direction="config.voice_interface.frequDirection"
      :frequ-lnum="config.voice_interface.frequLnum" :canv-width="config.voice_interface.canvWidth"
      :line-color="config.voice_interface.lineColor" :line-width="config.voice_interface.lineWidth">
    </AVMedia>
  </div>
</template>

<script>
import EventBus from '../event-bus';
import { AVMedia } from 'vue-audio-visual';
import config from '../config';
import stopwords from "../assets/stopwords.js";
import { nanoid } from 'nanoid';

const getUserMedia = require('get-user-media-promise');
const MicrophoneStream = require('microphone-stream');
const downsampleBuffer = require('../downsampleBuffer');
const isMobile = require('is-mobile');

const nlp = window.nlp;

export default {
  data() {
    return {
      config: config,
      socket: null,
      connected: false,
      micStream: null,
      segments_timeout: null,
      media: null,
      last_log: 0,
      detectedSampleRate: 44100,
      micInitialized: false,
      last_transcript: 0,
      silence_timeout: null,

      window_invisible_timeout_ms: 15 * 1000,

      isMobile: isMobile({ tablet: true }),

      isVisible: true,

      stream_id: nanoid()
    };
  },
  methods: {
    stopAwaitingSegments() {
      clearTimeout(this.segments_timeout);
      this.$store.commit('set_awaiting_segments', false);
    },

    startAwaitingSegments() {
      clearTimeout(this.segments_timeout);

      this.$store.commit('set_awaiting_segments', true);

      // add failsafe for if segments don't return; 20s = job queue timeout
      this.segments_timeout = setTimeout(() => {
        console.error('mic: timed out awaiting segments!');
        this.$store.commit('set_awaiting_segments', false);
      }, 20.5 * 1000);
    },
    // if on mobile and document becomes invisible to user, time out the mic
    // in a shorter time frame than normal
    onVisibilityChange() {
      this.isVisible = document.visibilityState === 'visible';

      // no special handling needed if mic not activated
      if (!this.micInitialized) {
        return;
      }

      if (this.isVisible) {
        this.resetSilentTimeout();
      }
      else {
        this.resetSilentTimeout(this.window_invisible_timeout_ms);
      }
    },
    emitMicSetup() {
      // ignore if mic not initialized
      if (!this.micInitialized) {
        return;
      }

      this.socket.emit('mic setup');
    },
    setupInputStreamAuto() {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => this.setupInputStream());
    },
    setupInputStream() {
      this.micStream = new MicrophoneStream({
        objectMode: false // true = get an AudioBuffer, false = get 32bit float arr
      });

      this.micStream.on('data', (chunk) => {
        // chunk = Uint8Array DataView
        // optionally, convert it to raw Float32Array
        // var raw = MicrophoneStream.toRaw(chunk)

        if (!this.micInitialized) {
          return;
        }

        const buf = downsampleBuffer.downsampleSimple(MicrophoneStream.toRaw(chunk),
          this.detectedSampleRate, 16000);

        this.socket.emit('mic data', {
          stream_id: this.stream_id,
          sample_rate: 16000,
          chunk: buf
        });
      });

      // this is emitted when the stream starts
      this.micStream.on('format', format => {
        this.detectedSampleRate = format.sampleRate;
      });

      getUserMedia({ video: false, audio: true }).then(stream => {
        this.micStream.setStream(stream);

        this.micInitialized = true;
        this.emitMicSetup();
        this.resetSilentTimeout();
        this.media = stream;
      }).catch(err => {
        this.micInitialized = false;
        console.err('mic: error with getUserMedia()');
        console.error(err);
      });
    },
    closeStream() {
      this.micInitialized = false;
      this.micStream.stop();
    },
    handleSilentStream() {
      EventBus.$emit('silent-stream');
      if (config.close_silent_stream) {
        console.log('mic: closing silent stream');
        this.closeStream();
      }
    },
    resetSilentTimeout(ms) {
      ms = ms || this.config.silence_timeout_ms;
      if (ms === -1) {
        console.log('mic: silence timeout disabled; not resetting');
        clearTimeout(this.silence_timeout);
        return;
      }
      clearTimeout(this.silence_timeout);
      this.silence_timeout = setTimeout(this.handleSilentStream, ms);
    },
    parseInput(input) {
      const doc = nlp(input);
      const np = doc.nouns().json().map(noun => noun.text);

      const filtered = np.filter(noun => !stopwords.includes(noun));

      const parsed = {
        transcript: input,
        key_phrases: filtered
      };

      this.$store.commit('set_parsed', parsed);
    },
  },
  watch: {
    connected(status) {
      if (!status) {
        this.stopAwaitingSegments();
      }
    },
    micInitialized() {
      this.$store.commit('set_mic_enabled', this.micInitialized);
    },
    last_transcript() {
      // don't reset silent timeout if we are out of focus on mobile; we
      // want the stream to end after a shorter timeout
      if (this.isMobile && !this.hasFocus) {
        return;
      }

      this.resetSilentTimeout();
    }
  },
  computed: {
    awaiting_segments() {
      return this.$store.state.awaitingSegments;
    },
    regex() {
      return new RegExp(this.config.blacklisted_phrases.split(',').map(word => word.trim()).join('|'), 'gi');
    }
  },
  created() {
    EventBus.$on('activate microphone',
      config.auto_open_mic ? this.setupInputStreamAuto : this.setupInputStream);
    EventBus.$on('close microphone', this.closeStream);
    document.addEventListener('visibilitychange', this.onVisibilityChange, false);
  },
  mounted() {
    this.socket = io.connect();
    this.socket.binaryType = 'arraybuffer';

    this.socket.on('connect', () => {
      this.connected = true;
    });
    this.socket.on('reconnect', () => {
      this.connected = true;

      // try to instantly reinit mic on backend, if possible
      this.emitMicSetup();
    });
    this.socket.on('disconnect', () => {
      this.connected = false;
    });

    this.socket.on('error', (err) => {
      console.error('mic: socketio err');
      console.error(err);
    });

    this.socket.on('transcript', (data) => {
      this.last_transcript = new Date();

      // only allow one pending segmentation per client, for backend perf
      if (this.awaiting_segments) {
        console.warn('mic: ignoring transcript event; awaiting segments');
        return;
      }

      if (!data.transcript) {
        console.warn('mic: ignoring transcript event; missing transcript');
        return;
      }

      const term = data.transcript.trim().replace(this.regex, '');
      if (term === '') return;

      EventBus.$emit('transcript', term);

      // tell the backend we've decided on our segment term
      if (data.final) {
        this.startAwaitingSegments();

        this.parseInput(term);

        console.log('mic: requesting segments for term', term);

        // if keyphrases only, then send just the key phrases;
        // otherwise, send the segment term
        this.socket.emit('segment term', {
          term,
          min_segments: 10,
        });
      }

      this.$store.commit('set_partial_results', term);
    });

    this.socket.on('segments', segments => {
      EventBus.$emit('segments', segments);
      this.stopAwaitingSegments();
      this.$store.commit('set_segmenter_error', false);
    });

    // no segments returns, or error generating segments
    this.socket.on('segmenter error', () => {
      console.error('mic: got back segmentation error');
      this.stopAwaitingSegments();
      this.$store.commit('set_segmenter_error', true);
    });
  },
  beforeDestroy() {
    EventBus.$off('activate microphone',
      config.auto_open_mic ? this.setupInputStreamAuto : this.setupInputStream);
    EventBus.$off('close microphone', this.closeStream);
    document.removeEventListener('visibilitychange', this.onVisibilityChange);

    clearTimeout(this.silence_timeout);

    clearTimeout(this.segments_timeout);

    this.micStream.stop();
    if (this.socket) this.socket.close();
  },
  components: {
    AVMedia
  }
};
</script>

<style lang="scss" scoped>
#mic-button {
  position: fixed;
  top: 0;
  left: 0;
  background-color: red;
  color: white;
}

#waveform-container {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media only screen and (min-width: 1920px) {
  #waveform-container {
    margin-top: -115px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1919px) {
  #waveform-container {
    margin-top: -92px;
  }
}

@media only screen and (max-width: 767px) {
  #waveform-container {
    margin-top: -81px;
  }
}
</style>
