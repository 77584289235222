const default_web = {
  show_footer: true,
  show_welcome: true,
  auto_open_mic: false,
  silence_timeout_ms: 75 * 1000,
  close_silent_stream: true,
  voice_interface: {
    type: 'searchbar'
  },
  use_png_mask: false,
  safe_transcription: true,
  safe_image: true,
  allow_user_configuration: false,
  graphics: {
    ring_interval: 500,
    outward_speed: 1,
    rotation_speed: 1,
    key_phrases_only: false
  },
  blacklisted_phrases: '',
  use_css_gradient:true,
  gradient: {
    type: 'radial',
    linear:{
      top_start: 0,
      top_end:10,
      right_start: 0,
      right_end:10,
      bottom_start: 0,
      bottom_end:10,
      left_start: 0,
      left_end:10,
      top_left_start:0,
      top_left_end:10,
      top_right_start:0,
      top_right_end:10,
      bottom_left_start:0,
      bottom_left_end:10,
      bottom_right_start:0,
      bottom_right_end:10,
    },
    radial:{
      circle: true,
      radial_start:25,
      radial_end:75
    }
  },
};

const default_electron = {
  show_footer: false,
  show_welcome: false,
  auto_open_mic: true,
  silence_timeout_ms: -1, // disable during kiosk (always listening)
  close_silent_stream: false,
  voice_interface: {
    type: 'frequ',
    frequLnum: 30,
    frequLineCap: true,
    frequDirection: 'mo',
    lineColor: '#FFFFFF',
    lineWidth: 3
  },
  use_png_mask: false,
  allow_user_configuration: true,
  safe_transcription: true,
  safe_image: true,
  graphics: {
    ring_interval: 2000,
    outward_speed: .8,
    rotation_speed: 1,
    key_phrases_only: false
  },
  blacklisted_phrases: '',
  use_css_gradient:true,
  gradient: {
    type: 'radial',
    linear:{
      top_start: 0,
      top_end:10,
      right_start: 0,
      right_end:10,
      bottom_start: 0,
      bottom_end:10,
      left_start: 0,
      left_end:10,
      top_left_start:0,
      top_left_end:10,
      top_right_start:0,
      top_right_end:10,
      bottom_left_start:0,
      bottom_left_end:10,
      bottom_right_start:0,
      bottom_right_end:10,
    },
    radial:{
      circle:true,
      radial_start:25,
      radial_end:75
    }
  },
};

const configs = {
  // Defaults
  web: default_web,
  electron: default_electron,

  // Specific instances
  new_childrens_museum: {
    ...default_electron
  }
};

const srcConfig = configs[process.env.VUE_APP_INSTANCE || 'web'];
function updateConfig(srcConfig, localConfig){
  let keys = Object.keys(srcConfig);
  for (const key in keys){
    if(typeof localConfig[keys[key]] === 'object'){
      updateConfig(srcConfig[keys[key]], localConfig[keys[key]]);
    }
    // Check if the key exists in localConfig without directly calling hasOwnProperty
    else if (Object.prototype.hasOwnProperty.call(localConfig, keys[key])) {
      srcConfig[keys[key]] = localConfig[keys[key]];
    }
  }
}
// Check for local override
let localConfig = undefined;
const localString = localStorage.getItem(process.env.VUE_APP_INSTANCE);
if (localString) {
  localConfig = JSON.parse(localString);
  updateConfig(srcConfig, localConfig);
  console.log('Config loaded from local storage');
}
else {
  console.log('No config found in local storage; using src/ config');
}

export default srcConfig;
